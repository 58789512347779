import React from "react"

import * as S from "./styles"

const About = () => (
  <S.ComponentWrapper>
    <S.Container>
      <S.Column>
        <S.Title>BURGUER DO SEU JEITO</S.Title>
        <S.Text>Aqui você monta seu sanduba! Com dezenas de opções em ingredientes, você tem uma infinidade de combinações e sabores. Nossos hambúrgueres, além de assados na brasa, são totalmente artesanais e feitos com 100% de carne. Todos os ingredientes são livres de conservantes, que combinados com os pães macios e crocantes, irão proporcionar muito mais sabor ao seu paladar. Você se surpreenderá com a GoBurguer!</S.Text>
      </S.Column>
    </S.Container>
  </S.ComponentWrapper>
)

export default About
