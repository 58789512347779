import React from "react"
import { Parallax } from "react-parallax"

import * as S from "./styles"

const Divider = ({ content }) => {
  return (
    <Parallax blur={0} bgImage={content} bgImageAlt="the cat" strength={200}>
      <div>
        <S.Title></S.Title>
      </div>
    </Parallax>
  )
}

export default Divider
