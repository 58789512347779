import React from "react"

import * as S from "./styles"

const MenuBrasilia = () => (
  <S.ComponentWrapper>
    <S.Nav>
      <S.NavLinks>
        <S.NavLinksItem>
          <S.NavLinksLink to="/brasilia">Home</S.NavLinksLink>
        </S.NavLinksItem>
        <S.NavLinksItem>
          <S.NavLinksLink to="/brasilia/#monte">Como funciona</S.NavLinksLink>
        </S.NavLinksItem>
        <S.NavLinksItem>
          <S.NavLinksLink to="/cardapio-brasilia">Cardápio</S.NavLinksLink>
        </S.NavLinksItem>
        <S.NavLinksItem>
          <S.NavLinksLink to="/brasilia/#peca">Faça seu pedido</S.NavLinksLink>
        </S.NavLinksItem>
      </S.NavLinks>
    </S.Nav>
  </S.ComponentWrapper>
)

export default MenuBrasilia
