import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const settings = {
  dots: true,
  infinite: true,
  speed: 350,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
}

const SliderHome = () => {
  const { img1, img2 } = useStaticQuery(
    graphql`
      query {
        img1: file(relativePath: { eq: "go-burguer-slide-frango-balde.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        img2: file(
          relativePath: { eq: "go-burguer-slide-infinitas-possibilidades.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className="sliderGB">
      <Slider {...settings}>
        <div>
          <Img fluid={img1.childImageSharp.fluid} />
        </div>
        <div>
          <Img fluid={img2.childImageSharp.fluid} />
        </div>
      </Slider>
    </div>
  )
}

export default SliderHome
