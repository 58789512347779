import styled from "styled-components"
import Img from "gatsby-image"

import bg from "../../images/go-burguer-bancada-sanduiches-molho-bg.jpg"

export const ComponentWrapper = styled.div`
  background: url(${bg}) center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 80%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MonteImg = styled(Img)`
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
`
