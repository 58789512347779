import styled from "styled-components"

export const Title = styled.h2`
  color: white;
  font-family: zonaproblack;
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
  padding: 50px 0;
`
