import styled from "styled-components"
import Img from "gatsby-image"

export const ContentWrapper = styled.div`
  padding: 80px 0;
`

export const Logo = styled(Img)`
  max-width: 400px;
  display: block;
  margin: 0 auto;

  @media (max-width: 980px) {
    max-width: 300px;
    margin: 0px auto;
  }

  @media (max-width: 768px) {
    max-width: 220px;
    margin: 0px auto;
  }
`
