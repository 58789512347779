import React from "react"
import { Parallax } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

import bg from "../../images/banner-ifood-bg.jpg"

const PedidoBrasilia = () => {
  const { logoIfood, logoGoburguer } = useStaticQuery(
    graphql`
      query {
        logoIfood: file(relativePath: { eq: "logo-ifood.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoGoburguer: file(relativePath: { eq: "go-burguer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Parallax
      blur={0}
      bgImage={bg}
      bgImageAlt="the cat"
      strength={200}
      style={{ marginTop: "-3px" }}
    >
      <S.ComponentWrapper id="peca">
        <S.Container>
          <S.Title>Faça seu pedido online!</S.Title>
          <S.SubTitle>É só clicar e pedir.</S.SubTitle>
          <S.LogosWrapper>
            <div>
              <a
                href="https://www.ifood.com.br/delivery/brasilia-df/go-burguer-taguatinga/e14c982e-483c-4ec8-87c1-97509380eddd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.Logo fluid={logoGoburguer.childImageSharp.fluid} />
              </a>
            </div>
            <div>
              <a
                href="https://www.ifood.com.br/delivery/brasilia-df/go-burguer-taguatinga/e14c982e-483c-4ec8-87c1-97509380eddd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.Logo fluid={logoIfood.childImageSharp.fluid} />
              </a>
            </div>
          </S.LogosWrapper>
        </S.Container>
      </S.ComponentWrapper>
    </Parallax>
  )
}

export default PedidoBrasilia
