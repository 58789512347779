import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import * as S from "./styles"

const Monte = () => {
  const { img1, img2, img3, img4, img5, img6 } = useStaticQuery(
    graphql`
      query {
        img1: file(relativePath: { eq: "1-go-burguer-monte-o-seu.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        img2: file(relativePath: { eq: "2-go-burguer-monte-o-seu.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        img3: file(relativePath: { eq: "3-go-burguer-monte-o-seu.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        img4: file(relativePath: { eq: "4-go-burguer-monte-o-seu.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        img5: file(relativePath: { eq: "5-go-burguer-monte-o-seu.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        img6: file(relativePath: { eq: "6-go-burguer-monte-o-seu.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.ComponentWrapper id="monte">
      <S.Container>
        <Link
          to="/passo1"
          state={{
            modal: true,
          }}
          style={{ width: "100%" }}
        >
          <S.MonteImg fluid={img1.childImageSharp.fluid} />
        </Link>
        <Link
          to="/passo2"
          state={{
            modal: true,
          }}
          style={{ width: "100%" }}
        >
          <S.MonteImg fluid={img2.childImageSharp.fluid} />
        </Link>
        <Link
          to="/passo3"
          state={{
            modal: true,
          }}
          style={{ width: "100%" }}
        >
          <S.MonteImg fluid={img3.childImageSharp.fluid} />
        </Link>
        <Link
          to="/passo4"
          state={{
            modal: true,
          }}
          style={{ width: "100%" }}
        >
          <S.MonteImg fluid={img4.childImageSharp.fluid} />
        </Link>
        <Link
          to="/passo5"
          state={{
            modal: true,
          }}
          style={{ width: "100%" }}
        >
          <S.MonteImg fluid={img5.childImageSharp.fluid} />
        </Link>
        <S.MonteImg fluid={img6.childImageSharp.fluid} />
      </S.Container>
    </S.ComponentWrapper>
  )
}

export default Monte
