import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

import Hero from "../components/Hero"
import About from "../components/About"
import Monte from "../components/Monte"
import Divider from "../components/Divider"

import img1 from "../images/go-burguer-jogo-basquete-bg.jpg"
import img2 from "../images/go-burguer-jogo-nfl-bg.jpg"
import SliderHome from "../components/SliderHome"
import MenuBrasilia from "../components/MenuBrasilia"
import PedidoBrasilia from "../components/PedidoBrasilia"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MenuBrasilia />
    <Hero />
    <About />
    <Divider content={img1} />
    <Monte />
    <Divider content={img2} />
    <SliderHome />
    <PedidoBrasilia />
  </Layout>
)

export default IndexPage
