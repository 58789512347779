import React from "react"
import { Parallax } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

const Hero = () => {
  const { logoImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "go-burguer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Parallax
      blur={0}
      bgImage={require("../../images/go-burguer-jogador-nfl-bg.jpg")}
      bgImageAlt="the cat"
      strength={200}
      style={{ marginTop: "67px" }}
    >
      <S.ContentWrapper>
        <S.Logo fluid={logoImage.childImageSharp.fluid} />
      </S.ContentWrapper>
    </Parallax>
  )
}

export default Hero
