import styled from "styled-components"

import bg from "../../images/go-burguer-hamburguer-bacon-cheddar-bg.jpg"

export const ComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: url(${bg}) center/cover;

  @media (max-width: 768px) {
    background-position-x: 20%;
  }

  @media (max-width: 650px) {
    background-position: center;
  }
`

export const Container = styled.div`
  margin: 15px 0;
  width: 100%;
  max-width: 1080px;
  display: flex;

  justify-content: space-between;
  padding: 20px;
`

export const Column = styled.div`
  width: 48%;

  %:not(:last-child) {
    margin-right: 2%;
  }

  @media (max-width: 768px) {
    &:first-child {
      width: 60%;
    }
  }

  @media (max-width: 650px) {
    &:first-child {
      width: 100%;
    }
  }
`

export const Title = styled.h2`
  font-family: Arvo;
  font-weight: bold;
  text-align: right;
  font-size: 32px;
  max-width: 250px;
  margin: 0 15px 10px auto;
  line-height: 1.1;

  @media (max-width: 650px) {
    text-shadow: 1px 1px 2px #efe9e9, -1px -1px 2px #efe9e9,
      1px -1px 2px #efe9e9, -1px 1px 2px #efe9e9;
  }
`

export const Text = styled.p`
  line-height: 1.7;
  text-align: right;
  margin-right: 15px;

  @media (max-width: 650px) {
    text-shadow: 1px 1px 2px #efe9e9, -1px -1px 2px #efe9e9,
      1px -1px 2px #efe9e9, -1px 1px 2px #efe9e9;
  }
`
