import styled from "styled-components"
import Img from "gatsby-image"

export const ComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h2`
  color: white;
  font-family: Arvo;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
`

export const SubTitle = styled.h4`
  font-size: 20px;
  color: white;
  margin-top: 5px;
`

export const LogosWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 50%;
  align-items: center;

  div {
    flex: 1;
  }
`

export const Logo = styled(Img)`
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
`
